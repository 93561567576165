<script setup lang="ts">
import { ROUTE_NAMES } from '@portal/constants/routes'

const { t } = useI18n()
const localeRoute = useLocaleRoute()
const { backendToken } = useBackendToken()
const appConfig = useAppConfig()
const { icons } = useDesign()
const { ACCOUNT, ACCOUNT_WATCHLIST } = ROUTE_NAMES

defineOptions({
  name: 'PageHeader',
})
</script>

<template>
  <header id="header" class="z-50 print:hidden">
    <PageContainer
      :default-theme="false"
      :default-vertical-spacing="false"
      :grow="false"
      class="bg-header bleed-header skin:bleed-none bleed-bg flex h-[var(--header-height)] items-center justify-between md:justify-start"
    >
      <PageHeaderNavigation
        class="order-first md:order-2 md:ml-auto print:hidden"
      />

      <PageLogo
        id="logo"
        :preload="true"
        :responsive="true"
        class="order-2 ml-4 mr-auto h-6 md:order-first md:mx-0"
      />

      <PageHeaderSearch
        class="order-4 md:order-3 md:max-w-xs md:grow md:px-6 print:hidden"
      />

      <div
        class="order-3 flex items-center space-x-1 empty:hidden md:order-4 print:hidden"
      >
        <NuxtLink
          v-if="appConfig.enableWatchList"
          :to="localeRoute(ACCOUNT_WATCHLIST)"
          class="button-header button button-sm !size-10 !min-w-0 pl-0 pr-0 md:inline-flex"
        >
          <Icon :name="icons.BOOKMARK_OUTLINE" class="size-6 fill-current" />
        </NuxtLink>

        <NuxtLink
          :title="t('account')"
          :to="
            localeRoute({
              name: ACCOUNT,
            })
          "
          class="button-header button button-sm hidden !size-10 !min-w-0 pl-0 pr-0 md:inline-flex"
        >
          <Icon
            :name="icons.ACCOUNT_CIRCLE_OUTLINE"
            class="size-6 fill-current"
          />
        </NuxtLink>

        <Builder v-if="backendToken" />
      </div>
    </PageContainer>
  </header>
</template>

<style>
:root {
  --header-height: 4rem;
  scroll-padding-top: var(--header-height);
}
</style>

<i18n>
de:
  account: "Konto"
  accountUrl: "konto"
en:
  account: "Account"
  accountUrl: "account"
es:
  account: "Cuenta"
  accountUrl: "cuenta"
</i18n>
