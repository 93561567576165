<script lang="ts" setup>
import type { City } from '#gql/default'

interface Props {
  city: City
}

const { city } = defineProps<Props>()
const { icons } = useDesign()

const postcodes = computed(() =>
  [
    city.postcodes?.[0]?.postcode,
    city.postcodes?.[city.postcodes.length - 1]?.postcode,
  ]
    .filter(Boolean)
    .join(' - '),
)

defineOptions({
  name: 'SearchAutosuggestResultCity',
})
</script>

<template>
  <CityLink :city>
    <Icon
      :name="icons.MAP_MARKER_OUTLINE"
      class="dark:fill-neutral fill-neutral-dark-2 size-6"
    />
    <div>
      <div v-text="city.name" />
      <div v-if="postcodes" class="text-2xs" v-text="postcodes" />
    </div>
  </CityLink>
</template>
