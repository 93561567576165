<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greaterOrEqual('md')

const { t } = useI18n()
const { icons } = useDesign()
const query = ref('')
const selectedTypes = ref([])

const isOffCanvasVisible = ref(false)
const isDropdownVisible = ref(false)

function toggleDropdown() {
  isDropdownVisible.value = mdAndLarger.value && query.value?.length > 2
  setFocus(0)
}

function reset() {
  query.value = ''
  isDropdownVisible.value = false
}

const { handleKeyDown, setFocus } = useKeyboardNavigation({
  selector: '[data-autosuggest-dropdown] input, [data-autosuggest-dropdown] a',
  initialFocus: 0,
  onEscape() {
    reset()
  },
  onEnter(item: HTMLElement) {
    if (item.getAttribute('name') === 'search') {
      item.closest('form')?.submit()
    }
    reset()
  },
})

watch([query, mdAndLarger], toggleDropdown)

defineOptions({
  name: 'PageHeaderSearch',
})
</script>

<template>
  <div @keydown="handleKeyDown">
    <Dropdown
      class="hidden w-full md:flex"
      :classes="{ dropdown: 'w-96' }"
      :open="mdAndLarger && isDropdownVisible"
      :hide-button="true"
      data-autosuggest-dropdown
      :placement="DROPDOWN_PLACEMENT.BOTTOM_END"
      @close="isDropdownVisible = false"
    >
      <template #trigger>
        <SearchAutosuggestForm v-model="query" @focus="toggleDropdown" />
      </template>

      <template #header>
        <SearchAutosuggestFilters
          v-model="selectedTypes"
          class="[&>button]:flex-grow"
        />
      </template>

      <template #default>
        <SearchAutosuggestResults
          v-if="isDropdownVisible && query?.length > 2"
          :selected-types
          :query
          :classes="{
            link: 'text-dark dark:text-light hover:bg-neutral-light-1 dark:hover:bg-neutral-dark-1',
            noResults: 'px-2.5 text-xs text-black dark:text-light',
          }"
          @select="reset"
        />
      </template>
    </Dropdown>

    <KButton
      :icon="icons.MAGNIFYING_GLASS"
      :theme="BUTTON_THEME.TRANSPARENT"
      :title="t('btn.search')"
      class="text-on-header hover:bg-on-header/10 flex rounded-lg px-6 md:hidden"
      @click="isOffCanvasVisible = true"
    />
    <LazyPageHeaderSearchOffCanvas
      v-if="!mdAndLarger && isOffCanvasVisible"
      @close="isOffCanvasVisible = false"
    />
  </div>
</template>

<i18n>
de:
  btn:
    search: "Suche"
es:
  btn:
    search: "Buscar"
</i18n>
